.section-form
  .title
    margin-bottom 25px
    color $white
    font-family $font-family-accent
    font-size 30px
    font-weight $font-weight-normal
    text-align center

  .lead
    display block
    margin-bottom 18px
    padding-left 12px
    color $white
    font-size 15px
    letter-spacing -0.015em

.form
  position relative

  &__content
    display block

  &__done
    display none
    padding-top 25px

    .subtitle
      margin-bottom 25px
      font-size 30px
      font-weight $font-weight-normal
      text-align center
      letter-spacing -0.05em

    .text
      margin-bottom 32px
      font-size 15px
      line-height (24/15)
      text-align center

    svg
      display block
      width 124px
      height auto
      margin 0 auto 60px

    .rules
      padding-right 20px
      padding-left 20px

      &-title
        padding-right 20px
        padding-left 20px
        font-size 15px
        font-weight $font-weight-normal

      &__item
        font-size 15px
        line-height 1.2

        sup
          vertical-align baseline

  &--done
    .form
      &__content
        display none

      &__done
        display block
