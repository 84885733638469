body
  background-color #000
  background-image url(../img/bg.png)
  background-position top center
  background-size 100%
  background-repeat no-repeat

.vc-main
  max-width $max-width
  margin 0 auto
  padding-bottom 100px
  overflow hidden
  background 0

.wrapper
  margin-right auto
  margin-left auto
  padding-right $gutter
  padding-left $gutter

.sr-only
  position absolute
  width 1px
  height 1px
  padding 0
  margin -1px
  overflow hidden
  clip rect(0, 0, 0, 0)
  white-space nowrap
  border 0

