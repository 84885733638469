*
*::before
*::after
  box-sizing border-box

:focus
  outline none

html
  font-family sans-serif
  line-height 1.15
  -webkit-text-size-adjust 100%
  -webkit-tap-highlight-color rgba($black, 0)

body
  margin 0
  overflow-x hidden
  color $body-color
  font-family $font-family-base
  font-size $font-size-base
  font-smooth always
  font-weight $font-weight-base
  line-height $line-height-base
  text-align left
  background-color $body-bg
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

article
aside
figcaption
figure
footer
header
hgroup
main
nav
section
  display block

[tabindex="-1"]:focus:not(:focus-visible)
  outline 0 !important

hr
  height 0
  box-sizing content-box
  overflow visible

h1
h2
h3
h4
h5
h6
p
  margin-top 0
  margin-bottom 0

ol
ul
dl
  margin 0
  padding-left 0
  list-style none

b
strong
  font-weight $font-weight-bold

a
  text-decoration none

  &:hover
    text-decoration none

button
  border-radius 0
  cursor pointer

  &:focus
    outline 0 none

input
button
select
textarea
  margin 0
  font-family inherit
  font-size inherit
  line-height inherit

input
button
  overflow visible

button
select
  text-transform none

[role='button']
  cursor pointer

select
  word-wrap normal

button::-moz-focus-inner
[type="button"]::-moz-focus-inner
[type="reset"]::-moz-focus-inner
[type="submit"]::-moz-focus-inner
  padding 0
  border-style none

textarea
  overflow auto
  resize vertical

[type="number"]::-webkit-inner-spin-button
[type="number"]::-webkit-outer-spin-button
  height auto

[type="search"]
  outline-offset -2px
  -webkit-appearance none

[hidden]
  display none !important
