.btn-wrap
  display flex
  justify-content center
  flex 1
  padding-top 60px

.btn
  position relative
  display inline-flex
  align-items center
  justify-content center
  width 100%
  height 50px
  padding 2px 40px 6px
  border none
  border-radius 0
  box-shadow 0 12px 10.08px 1.92px rgba(0, 0, 0, .2)
  color $white
  font-family $font-family-accent
  font-size 30px
  font-style italic
  font-weight $font-weight-normal
  background-image -moz-linear-gradient( 90deg, rgb(126,110,89) 0%, rgb(152,132,106) 99%)
  background-image -webkit-linear-gradient( 90deg, rgb(126,110,89) 0%, rgb(152,132,106) 99%)
  background-image -ms-linear-gradient( 90deg, rgb(126,110,89) 0%, rgb(152,132,106) 99%)

  &__loading
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    pointer-events none
    opacity 0

  .ripple
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden

    .circle
      position absolute
      top 50%
      left 50%
      border 1px solid transparent
      border-radius 50%
      pointer-events none
      background rgba(251, 232, 211, .2)
      animation ripple .75s 1


@keyframes ripple
  from
    transform scale(0)
    opacity 1

  to
    opacity 0
    transform scale(250)
