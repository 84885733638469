.field
  position relative
  display flex
  width 100%

  &-group
    display flex
    align-items center
    flex 1 1 auto
    flex-flow row-reverse
    margin-bottom 10px

  &-control
    display flex
    flex 1 1 auto
    width 100%
    height 50px
    padding 4px 13px
    border 1px solid #6f6f6f
    border-radius 5px
    color #717171
    font-family $font-family-support
    font-size 16px
    font-style italic
    font-weight $font-weight-normal
    background-color $white
    transition 250ms ease

    &::-webkit-input-placeholder
      color rgba(#717171, .5)
      text-transform none

    &::-moz-placeholder
      color rgba(#717171, .5)
      text-transform none

    &:-ms-input-placeholder
      color rgba(#717171, .5)
      text-transform none

    &:-moz-placeholder
      color rgba(#717171, .5)
      text-transform none

    &:is(textarea)
      padding-top 30px
      height 280px
      border 1px solid
      resize none
      border-radius 3px

      & + .field-label
        position absolute
        top 9px
        left 10px

        &:after
          display none

  &-select
    position relative
    display block
    width 100%
    height 48px
    padding 4px 32px 4px 12px
    border-radius 5px
    border 1px solid #6f6f6f
    color #717171
    font-size 16px
    font-style italic
    font-weight $font-weight-normal
    vertical-align middle
    background-color $white
    background-image url(../img/angle-down.svg)
    background-position right 12px center
    background-repeat no-repeat
    background-size 14px
    -ms-appearance none
    appearance none

    &::-ms-expand
      display none

    &:focus
      border-color #7e6e59

    &:disabled
      color rgba(#717171, .5)

    &:not(:focus):invalid
      color rgba(#717171, .5)

.was-validated
  .custom-check__input:invalid
    border-color $color-error

    &~.custom-check__label
      color $color-error

  .field-select:invalid
  .field-control:invalid
    border-color $color-error
    color $color-error

    &::-webkit-input-placeholder
      color $color-error

    &::-moz-placeholder
      color $color-error

    &:-ms-input-placeholder
      color $color-error

    &:-moz-placeholder
      color $color-error

  &:invalid
    .feedback--error
      display flex

.is-invalid~.field__feedback
  display flex
