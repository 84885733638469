.custom-check
  display block
  margin-bottom 3px
  padding-top 5px
  padding-left 35px

  &__label
    display inline-block
    color $white
    font-size 18px
    font-weight $font-weight-normal
    letter-spacing -0.025em
    line-height (24/18)

  &__input
    appearance none
    float left
    width 24px
    height 24px
    margin-left -35px
    border 1px solid #6f6f6f
    border-radius 5px
    vertical-align top
    background-color $white
    background-position center center
    background-size 70%
    background-repeat no-repeat
    -webkit-print-color-adjust exact

    &:checked[type=checkbox]
      background-image url(../img/check.svg)

    &.is-invalid
      border-color $color-error

      &~.custom-check__label
        color $color-error

