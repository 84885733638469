.vc-header
  margin-bottom 10px
  padding-top 60px

  .wrapper
    display flex
    justify-content center

  .brand
    width 154px
    height 154px
