.feedback
  position absolute
  left 0
  display flex
  width 100%
  margin-bottom 20px
  padding 12px 32px
  font-size 12px
  font-weight $font-weight-semibold
  font-style italic

  &&--error
    display none
    color $white
    background-color #ff0000

  &.show
    display flex !important
