.gl-footer
  position relative
  padding-top 30px
  background-color $color-primary

  .wrapper
    display flex

    +above($md)
      justify-content space-between

    +below($sm-max)
      flex-direction column
      align-items center

    .wrap
      display flex

      +above($md)
        align-items center

      +below($sm-max)
        flex-direction column

  > .wrapper
    padding-bottom 25px

  .copyright
    display flex
    color $white
    font-size 9px
    font-weight $font-weight-medium
    line-height (14/9)

    +below($sm-max)
      justify-content center
      text-align center

  .menu
    display flex
    align-items center

    +above($md)
      padding-right 28px
      padding-left 28px

    +below($sm-max)
      justify-content space-between
      padding-top 20px
      padding-bottom 20px

    &-item
      font-size 0

      & + .menu-item
        margin-left 24px

      a
        color $color-golden
        font-size 9px
        font-weight $font-weight-medium
        line-height (14/9)
        transition $time-hover $ease-hover

        &:hover
          opacity .6

  .brand
    display flex
    align-items center
    justify-content center
    width 131px
    height 28px

    svg
      width 100%
      height 100%

  .signature-bar
    padding-top 8px
    padding-bottom 8px
    background-color #001623

    .wrapper
      justify-content flex-end
      color $white
      font-size 9px
      font-weight $font-weight-medium
      text-align right

      a
        margin-left 3px
        color $white
        font-size 9px
        font-weight $font-weight-medium
